<template>
    <div class="abonements">
        <b-overlay
            :show="busy"
            rounded
            opacity="0.6"
            spinner
            spinner-variant="bruvis"
        >
            <b-row>
                <b-col md="3">
                    <b-input-group>
                        <b-form-select
                            v-if="services"
                            v-model="currentService"
                            @change="getSpecialists"
                            class="shadow-none"
                        >
                            <template #first>
                                <b-form-select-option :value="null" disabled>-- Оберіть послугу --</b-form-select-option>
                            </template>
                            <!--template v-for="a in services">
                                <b-form-select-option 
                                    :value="a.service"
                                    :key = a.service
                                >{{a.xservice}}</b-form-select-option>
                            </template-->  
                            <template v-for="ky in Object.keys(services)">
                                <b-form-select-option 
                                    :value="ky"
                                    :key = ky
                                >{{services[ky]}}</b-form-select-option>
                            </template>  
                        </b-form-select>
                        <b-input-group-append v-if="currentService">
                            <b-button @click="currentService=null;getSpecialists()" variant="outline-danger"><b-icon icon="x" class="pt-1"></b-icon></b-button>
                            
                        </b-input-group-append>
                    </b-input-group>    

                    <b-card class="text-center mt-2" header-tag="header">
                        <template #header>
                            <h5 class="mb-0 text-left">Спеціалісти</h5>
                        </template>

                        <b-list-group v-if="specialists" class="d-none d-md-block">
                            <template v-for="s in specialists">
                                <b-list-group-item button :key="s.worker" :active="(specialist && specialist.worker===s.worker && specialist.planRN===s.planRN)" @click="setSpecialist(s)">{{s.xworker}}</b-list-group-item>
                            </template>    
                        </b-list-group>

                        <b-form-select
                            v-if="specialists"
                            v-model="specialist"
                            @change="setSpecialist(specialist)"
                            class="shadow-none 	d-xs-block d-sm-block d-md-none"
                        >
                            <template v-for="s in specialists">
                                <b-form-select-option 
                                    :value="s"
                                    :key = s
                                >{{s.xworker}}</b-form-select-option>
                            </template>  
                        </b-form-select>
                    </b-card>  

                    
                        


                    <!--b-button v-b-toggle.collapse-table variant="primary" size="sm" class="mt-4">Toggle debug info</b-button-->
  
                </b-col>
                <b-col md="9">    
                    <template v-if="specialist">


                        <b-card class="text-center" header-tag="header">
                            <template #header>
                                <div class="hdr-specialist">
                                    <div class="text-left">
                                        <h5 v-if="specialist.xworker" class="mb-2 d-none d-md-block">{{specialist.xworker}}</h5>
                                        <div v-if="specialist.hallobj.length==1"><b-icon icon="geo-alt-fill" class="mr-2"></b-icon>{{specialist.hallobj[0].hallobjname}}</div>
                                        <div v-else>
                                            <b-icon icon="geo-alt-fill" class="mr-2"></b-icon> 
                                            <span 
                                                v-for="(hall,idx) in specialist.hallobj" :key="hall.hallobjRN" 
                                                class="text-nowrap my-2 mr-2 ml-0 p-2"
                                                :class="(selectHall==hall.hallobjRN)?'shift-checked sft-'+idx:'sft-'+idx"
                                            >{{hall.hallobjname}}</span>
                                        </div>
                                        <!--------------------------------------------->
                                        <div v-if="currentService">Послуга: {{services[currentService]}}</div>
                                        <div v-else-if="currentServicesList && currentServicesList.length===1">Послуга: {{services[currentServicePost]}}</div>
                                        <!--------------------------------------------->
                                        <b-form-select
                                            v-else-if="!currentService && currentServicesList && currentServicesList.length>1"
                                            v-model="currentServicePost"
                                            @change = "resetAbonByService()"
                                            class="shadow-none"
                                        >
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>-- Оберіть послугу --</b-form-select-option>
                                            </template>
                                            <template v-for="a in currentServicesList">
                                                <b-form-select-option 
                                                    :value="a.serviceRN"
                                                    :key = a.serviceRN
                                                >{{a.serviceName}}</b-form-select-option>
                                            </template>  
                                        </b-form-select>
                                        <!---------------------------->    


                                    </div>    
                                    <div class="text-right mt-3">
                                        <b-button v-if="checkedShifts.length>0" @click="switchSidebar('rightsidebar','on')" variant="bruvis">
                                            Забронювати
                                            <b-icon icon="clock-history" class="mx-1" shift-v="-1"/>
                                            {{selectFr}}-{{selectTo}}
                                        </b-button>
                                    </div>    
                                </div>    
                            </template>
                  
                           
                            <!--div class="weeks" v-if="specialist.hallobj[0].scheme[0].weeks">
                                <b-button 
                                    v-for="wk in Object.keys(specialist.hallobj[0].scheme[0].weeks).sort()" :key="wk" 
                                    :title="wk"
                                    size="sm" 
                                    class="mr-1 shadow-none" 
                                    @click="currentWeekIndex=wk" 
                                    :variant="(currentWeekIndex===wk)?'bruvis':'victor'" 
                                    role="button"
                                >
                                    {{new Date(specialist.hallobj[0].scheme[0].weeks[wk][0]) | dateFormat('D MMM')}} - {{new Date(specialist.hallobj[0].scheme[0].weeks[wk][(specialist.hallobj[0].scheme[0].weeks[wk]).length-1]) | dateFormat('D MMM')}}
                                </b-button>
                            </div-->

                            <!--div class="raspisanie" v-if="specialist.hallobj[0].scheme[0].daysarr && specialist.hallobj[0].scheme[0].weeks && specialist.hallobj[0].scheme[0].weeks[currentWeekIndex]">
                                <template  v-if="(new Date(specialist.hallobj[0].scheme[0].weeks[currentWeekIndex][0])).getDay()>1">
                                    <div class="raspisanie-col" v-for="n in (new Date(specialist.hallobj[0].scheme[0].weeks[currentWeekIndex][0])).getDay()-1" :key="n">
                                        <div class="raspisanie-day px-2">&nbsp;</div>
                                    </div>
                                </template>
                                <template  v-else-if="(new Date(specialist.hallobj[0].scheme[0].weeks[currentWeekIndex][0])).getDay()==0">
                                    <div class="raspisanie-col" v-for="n in 6" :key="n">
                                        <div class="raspisanie-day px-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                    </div>
                                </template>

                                <div class="raspisanie-col" v-for="dt in specialist.hallobj[0].scheme[0].weeks[currentWeekIndex]" :key="dt">
                                    <div class="raspisanie-day px-2" :title="(new Date(dt)).getDay()">{{new Date(dt) | dateFormat('dd DD.MM')}} </div>
                                </div> 
                            </div-->       

                            <!--b-row class="raspisanie" v-if="specialist && specialist.hallobj[0].scheme[0].daysarr && specialist.hallobj[0].scheme[0].weeks && specialist.hallobj[0].scheme[0].weeks[currentWeekIndex]">
                                <template  v-if="(new Date(specialist.hallobj[0].scheme[0].weeks[currentWeekIndex][0])).getDay()>1">
                                    <b-col class="p-0 raspisanie-col" v-for="n in (new Date(specialist.hallobj[0].scheme[0].weeks[currentWeekIndex][0])).getDay()-1" :key="n">
                                        <div class="raspisanie-day px-2">&nbsp;</div>
                                    </b-col>
                                </template>
                                <template  v-else-if="(new Date(specialist.hallobj[0].scheme[0].weeks[currentWeekIndex][0])).getDay()==0">
                                    <b-col class="p-0 raspisanie-col" v-for="n in 6" :key="n">
                                        <div class="raspisanie-day px-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                    </b-col>
                                </template>

                                <b-col class="p-0 raspisanie-col" v-for="dt in specialist.hallobj[0].scheme[0].weeks[currentWeekIndex]" :key="dt">
                                    <div class="raspisanie-day px-2" :title="(new Date(dt)).getDay()">{{new Date(dt) | dateFormat('dd DD.MM')}} </div>
                                </b-col> 
                            </b-row-->       


                            <!--div  v-for="(hall,idx) in specialist.hallobj" :key="hall.hallobjRN">
                                <div v-if="specialist.hallobj.length>1">{{hall.hallobjname}}</div>
                                <div  v-for="scheme in hall.scheme" :key="scheme.schemeRN">

                                    <b-collapse id="collapse-table">
                                         <div v-if="specialist.xworker">{{specialist.xworker}} ({{specialist.worker}})</div>

                                        <small v-if="scheme.dateFr || scheme.dateTo">
                                            <template v-if="scheme.dateFr">{{new Date(scheme.dateFr) | dateFormat('YYYY-MM-DD')}}</template>-
                                            <template v-if="scheme.dateTo">{{new Date(scheme.dateTo) | dateFormat('YYYY-MM-DD')}}</template>
                                        </small>    
                                        <small><b>schemeRN</b> : {{scheme.schemeRN}} <b>specRN</b> : {{scheme.specRN}} <b>startday</b> : {{scheme.startday}}</small> 

                                        <b-table hover sticky-header
                                            v-if="scheme.days"
                                            :items="scheme.days" 
                                            :fields="fields_specialist"
                                        >
                                            <template #cell(day)="data">
                                                <template v-if="data && data.item && data.item.day">{{ new Date(data.item.day) | dateFormat('YYYY-MM-DD')}}</template>
                                          </template>
                                        </b-table>
                                    </b-collapse>  

             

                                    <b-row cols="7" class="raspisanie" v-if="scheme.daysarr && scheme.weeks && scheme.weeks[currentWeekIndex]">
                                        <template  v-if="(new Date(scheme.weeks[currentWeekIndex][0])).getDay()>1">
                                            <b-col class="p-0 raspisanie-col bg-secondary" v-for="n in (new Date(scheme.weeks[currentWeekIndex][0])).getDay()-1" :key="n">
                                            </b-col>
                                        </template>
                                        <template  v-else-if="(new Date(scheme.weeks[currentWeekIndex][0])).getDay()==0">
                                            <b-col class="p-0 raspisanie-col bg-secondary" v-for="n in 6" :key="n">
                                                < !--div class="raspisanie-day px-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div-- >
                                            </b-col>
                                        </template>

                                        <b-col class="p-0 raspisanie-col" v-for="dt in scheme.weeks[currentWeekIndex]" :key="dt">
                                            <template v-if="scheme.daysarr[dt] && (scheme.daysarr[dt]).is_shift && shifts && shifts[(scheme.daysarr[dt]).shiftRN]">
                                                <div v-for="sft in (shifts[(scheme.daysarr[dt]).shiftRN]).spec" :key="sft.shiftSpecRN" :title="sft.shiftSpecRN" class="text-nowrap m-1 p-1 text-center small" :class="'sft-'+idx">
                                                    {{floatToTime(sft.timeFr)}}-{{floatToTime(sft.timeTo)}}
                                                </div>
                                            </template>  
                                        </b-col>
                                    </b-row>  


                                </div>
                            </div-->   


                            <div class="rasp-root" v-if="specialist" @touchstart="startTouch($event)" @touchmove="moveTouch($event)" @touchend="endTouch($event)">
                                <div class="rasp-left pt-3">
                                    <b-icon :icon="(currentDayIndex==0)?'caret-left':'caret-left-fill'" @selectstart.prevent :role="(currentDayIndex==0)?'':'button'" @click="clickDayPrev()"></b-icon> <!-- "clickDayPrev(Object.keys(specialist.hallobj[0].scheme[0].daysarr))"-->
                                </div>
                                <div class="rasp-container">
                                    <div class="rasp-content" id="rasp-content">
                                        <!--div class="p-0 rasp-col" v-for="dt in Object.keys(specialist.hallobj[0].scheme[0].daysarr)" :key="dt"-->
                                        <!--div class="p-0 rasp-col" v-for="dt in Object.keys(specialist.hallobj[0].dayindex)" :key="dt"-->    
                                        <div class="p-0 rasp-col" v-for="dt in specialist.dayindexfull" :key="dt">    
                                            <div class="rasp-day px-2 text-center" 
                                                :id="'dt-'+dt"
                                            >    
                                                <div v-if="(new Date(dt)).getDate()===(new Date()).getDate() && (new Date(dt)).getMonth()===(new Date()).getMonth()">Сьогодні</div>
                                                <div v-else>{{new Date(dt) | dateFormat('dd')}}</div>
                                                <div class="text-nowrap">{{new Date(dt) | dateFormat('D MMM')}}</div>
                                            </div>


                                            <template  v-for="(hall,idx) in specialist.hallobj" >
                                                <!--v-if="hall.scheme[0] && hall.scheme[0].daysarr[dt] && (hall.scheme[0].daysarr[dt]).shiftRN && shifts[(hall.scheme[0].daysarr[dt]).shiftRN]" -->    
                                                <!--0 - specialist.hallobj[0].dayindex[dt]-->
                                                <div 
                                                    class="p-0 rasp-shifts" 
                                                    v-if="specialist.hallobj[idx].dayindex[dt]!=null && hall.scheme[specialist.hallobj[idx].dayindex[dt]] && hall.scheme[specialist.hallobj[idx].dayindex[dt]].daysarr[dt] && (hall.scheme[specialist.hallobj[idx].dayindex[dt]].daysarr[dt]).shiftRN && shifts[(hall.scheme[specialist.hallobj[idx].dayindex[dt]].daysarr[dt]).shiftRN]" 
                                                    :key="hall.hallobjRN"
                                                >
                                                    <template v-for="(sft,sft_idx) in (shifts[(hall.scheme[specialist.hallobj[idx].dayindex[dt]].daysarr[dt]).shiftRN]).spec" >
                                                        <!--v-for="(sft,sft_idx) in (shifts[(hall.scheme[0].daysarr[dt]).shiftRN]).spec" -->
                                                        <!--v-if="getState(specialist.worker,dt,sft.shiftSpecRN)===0"-->
                                                        <div 
                                                            v-if="states && states[specialist.worker] && states[specialist.worker][dt] && states[specialist.worker][dt][sft.shiftSpecRN]===0"
                                                            class="text-nowrap m-2 p-2 text-center small rasp-shift" 
                                                            :class="(checkedShifts.indexOf('sft_' + ((sft_idx<10)?'0':'') + sft_idx + '_' + specialist.worker + '_' + hall.hallobjRN + '_' + dt + '_' + sft.shiftSpecRN)!=-1)?'sft-'+idx+' shift-checked '+shiftCheckedPos(sft_idx):'sft-'+idx"
                                                            :key="sft.shiftSpecRN" 
                                                            :title="specialist.worker + ' | ' + hall.hallobjRN + ' | ' + dt + ' | ' + sft.shiftSpecRN" 
                                                            :id="'sft_' + ((sft_idx<10)?'0':'') + sft_idx + '_' + specialist.worker + '_' + hall.hallobjRN + '_' + dt + '_' + sft.shiftSpecRN"
                                                            role="button"
                                                            @click="checkShift"
                                                            @selectstart.prevent
                                                        >
                                                            {{floatToTime(sft.timeFr)}}-{{floatToTime(sft.timeTo)}}
                                                        </div>
                                                        <div 
                                                            v-else-if="states && states[specialist.worker] && states[specialist.worker][dt] && states[specialist.worker][dt][sft.shiftSpecRN]===2"
                                                            class="text-nowrap m-2 p-2 text-center small rasp-shift sft--2" 
                                                            :key="sft.shiftSpecRN" 
                                                            :title="specialist.worker + ' | ' + hall.hallobjRN + ' | ' + dt + ' | ' + sft.shiftSpecRN" 
                                                            :id="'sft_' + ((sft_idx<10)?'0':'') + sft_idx + '_' + specialist.worker + '_' + hall.hallobjRN + '_' + dt + '_' + sft.shiftSpecRN"
                                                            @selectstart.prevent
                                                        >
                                                            {{floatToTime(sft.timeFr)}}-{{floatToTime(sft.timeTo)}}
                                                            <div class="booked">
                                                                <b-iconstack font-scale="1.5">
                                                                    <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                                                                    <b-icon stacked icon="check-circle" variant="success"></b-icon>
                                                                </b-iconstack>    
                                                            </div>
                                                        </div>
                                                        <div 
                                                            v-else
                                                            class="text-nowrap m-2 p-2 text-center small rasp-shift sft--0" 
                                                            :key="sft.shiftSpecRN" 
                                                            :title="specialist.worker + ' | ' + hall.hallobjRN + ' | ' + dt + ' | ' + sft.shiftSpecRN" 
                                                            :id="'sft_' + ((sft_idx<10)?'0':'') + sft_idx + '_' + specialist.worker + '_' + hall.hallobjRN + '_' + dt + '_' + sft.shiftSpecRN"
                                                            @selectstart.prevent
                                                        >
                                                            {{floatToTime(sft.timeFr)}}-{{floatToTime(sft.timeTo)}}
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>    




                                            <!--div class="p-0 rasp-shifts" v-if="(specialist.hallobj[0].scheme[0].daysarr[dt]).shiftRN && shifts[(specialist.hallobj[0].scheme[0].daysarr[dt]).shiftRN]">
                                                <div 
                                                    class="text-nowrap m-2 p-2 text-center small sft-0 rasp-shift" 
                                                    :class="'sft-'+idx"
                                                    v-for="sft in (shifts[(specialist.hallobj[0].scheme[0].daysarr[dt]).shiftRN]).spec" 
                                                    :key="sft.shiftSpecRN" 
                                                    :title="sft.shiftSpecRN" 
                                                >
                                                    {{floatToTime(sft.timeFr)}}-{{floatToTime(sft.timeTo)}}
                                                </div>
                                            </div-->

                                        </div> 
                                    </div>    
                                </div>        
                                <div class="rasp-right pt-3"><b-icon :icon="(isNextDay)?'caret-right-fill':'caret-right'" role="button" @selectstart.prevent @click="clickDayNext()"></b-icon></div> <!--"clickDayNext(Object.keys(specialist.hallobj[0].scheme[0].daysarr))"-->
                            </div>

                        </b-card>    



                    </template> 
                </b-col> 
            </b-row>       

                    
                    

                        <!--b-table hover sticky-header
                          v-if="grplessons"
                          :items="grplessons" 
                          :fields="fields_grplessons"
                          @row-clicked = "onRowClicked_gruppa"
                        >
                        </b-table>

                       <template v-if="gruppa">
                                <b-table hover sticky-header
                                  v-if="gruppa.days"
                                  :items="gruppa.days" 
                                  :fields="fields_gruppa"
                                >
                                    <template #cell(xday)="data">
                                        <template v-if="data && data.item && data.item.xday">{{ new Date(data.item.xday) | dateFormat('YYYY-MM-DD')}}</template>
                                    </template>
                                </b-table>
                        </template-->    

                    
                        <!--template>
                            <v-calendar 
                                trim-weeks
                                :locale="{ id: 'uk', firstDayOfWeek: 2, masks: { weekdays: 'WW' } }" 
                            />
                        </template-->    
                    



        </b-overlay>  

        <!-----------SIDEBAR BEGIN----------------------------------------------->

        <b-sidebar 
            id="rightsidebar" 
            ref="rightsidebar" 
            title="Бронювання" 
            right 
            shadow 
            backdrop-variant="dark"
            backdrop
            no-close-on-backdrop-
            sidebar-class="border-left border-bruvis" 
            header-class="bg-victor"
        >
            <template #footer="{ hide }">
              <div class="d-flex d-sm-none bg-transparent align-items-center px-3 py-2">
                <b-button @click="hide" class="bg-bruvis w-100 text-light p-2">Закрити та продовжити бронювання</b-button>
              </div>
            </template>

            <div class="px-3 py-2" v-if="specialist && ondate && hallNames">

                <b-overlay
                    :show="book_busy"
                    rounded 
                    variant="white"
                    blur="2px" 
                    opacity="0.6"
                    spinner-variant="bruvis"
                    class="d-inline-block w-100"
                >
                <div id="booking">
                      
<!--

     if(!this.currentService){
                this.specialist.hallobj.forEach((h)=>{
                    if(h.hallobjRN == this.selectHall){
                        this.currentServicesList = h.scheme[0].services
                        this.currentServicePost = null
                    }    
                })
            }else{
                this.currentServicePost = this.currentService
            }    

    -->
                    

                    <b-alert variant = "white" show fade class="text-left">
                        <h5>{{specialist.xworker}}</h5>
                        <h5>{{hallNames[selectHall]}}</h5>
                        <!--div v-if="currentService || (currentServicesList && currentServicesList.length===1) || (!currentService && currentServicesList && currentServicesList.length===1)">Послуга: {{services[currentServicePost]}}</div-->
                        <div v-if="services && currentServicePost && services[currentServicePost]">Послуга: {{services[currentServicePost]}}</div>
                        
                        <div>{{new Date(ondate) | dateFormat('dddd D MMMM YYYY')}}</div>
                        <div><b-icon icon="clock-history" class="mx-1" shift-v="-1"/> {{selectFr}}-{{selectTo}}</div>

                    </b-alert>  

                    <!--------------------------------------------->
                    <!--
                    <b-form-select
                        v-if="!currentService && currentServicesList.length>1"
                        v-model="currentServicePost"
                        @change = "resetAbonByService()"
                        class="shadow-none"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>-- Оберіть послугу --</b-form-select-option>
                        </template>
                        <template v-for="a in currentServicesList">
                            <b-form-select-option 
                                :value="a.serviceRN"
                                :key = a.serviceRN
                            >{{a.serviceName}}</b-form-select-option>
                        </template>  
                    </b-form-select>
                    -->
                    <!---------------------------->

                    <!--template v-if="!auth || !auth.visitor || !auth.visitor.visitorRN"-->
                    <template v-if="!visitor || !visitor.visitorRN">
                        <div class="text-left">
                            <b-form-input
                                id="booking_email"
                                v-model="booking_email"
                                type="email"
                                placeholder="Email"
                                required
                                class="mb-3"
                            ></b-form-input>
                            <b-form-input
                                id="booking_name"
                                v-model="booking_name"
                                placeholder="Введіть ваше ПІБ"
                                required
                                class="mb-3"
                            ></b-form-input>
                        </div>    
                    </template>
                    <!--template v-else-if="auth && auth.abons && Object.keys(auth.abons).length>0 && currentServicePost"-->
                    <template v-else-if="abons && Object.keys(abons).length>0 && currentServicePost">    
                        <div v-if="checkedShifts.length>0" class="text-left">
                            <b-form-checkbox 
                                v-model="useAbon"  
                                class="m-2 shadow-none"
                                :disabled="this.settings && this.settings.disable_liqpay===1"
                            >
                                Задіяти абонемент
                            </b-form-checkbox>
                            <b-collapse v-model="useAbon" class="p-0 m-0"><!--filter(item => validDateAbon(item,ondate)-->
                                <!--v-if="auth && auth.abons && Object.keys(auth.abons).length>0"-->
                                <b-form-select
                                    v-if="abons && Object.keys(abons).length>0"
                                    v-model="currentAbon"
                                    @change="changeAbon()"
                                    :disabled="!useAbon"
                                    class="shadow-none"
                                    :class="(useAbon && !currentAbon)?'border-danger mb-2':'mb-2'"
                                    :style="(useAbon && !validDateServiceAbon(abons[currentAbon],ondate,currentServicePost))?'border-danger;background-color:rgb(255, 114, 114);color: black;':'background-color: #fff;color: black;'"
                                >
                                    <template v-for="(a,rn) in this.$parent.$data.auth.abons">
                                        <b-form-select-option 
                                            :value="a.rn"
                                            :key = rn
                                            :style = "(!validDateServiceAbon(a,ondate,currentServicePost))?'color:red;background-color:white':'color:black;background-color:white'"
                                        >{{a.xnomenu}}</b-form-select-option>
                                    </template> 
                                </b-form-select>
                                <div class="alert alert-danger" v-if="!validDateServiceAbon(abons[currentAbon],ondate,currentServicePost)">
                                    <b-icon icon="exclamation-triangle-fill" class="mr-1"></b-icon>Невідповідний абонемент
                                </div> 
                            </b-collapse>    
                        </div>
                        
                    </template>

                    <!--b-alert 
                        variant = "info"
                        v-for="sts of checkedShifts" :key="sts"
                        show
                        fade
                        class="text-left"
                        >
                        {{sts}}
                        <h5>
                            <b-badge variant="light" class="mr-1"></b-badge>
                         
                        </h5>
                    </b-alert-->
               

                </div>

                <template>
                  <b-collapse v-model="isVisibleGoPay" class="p-0 m-0">
                    <div class="m-0 p-0 text-left"> <!--v-if="!useAbon || !currentAbon" -->
                        <b-form-checkbox v-model="readyToPay"  class="m-2">Перейти до оплати</b-form-checkbox>  
                    </div>  
                  </b-collapse>  

                  <b-button 
                    block
                    @click="goBook()"
                    variant="success"
                    class="mt-2 p-2 shadow-none"
                    :disabled="(useAbon && (!currentAbon || !validDateServiceAbon(abons[currentAbon],ondate,currentServicePost))) || !currentServicePost ||
                                ((!visitor || !visitor.visitorRN) && (!booking_email || !booking_name))"
                  >
                    Забронювати
                    <b-icon aria-hidden="true" :icon="(!useAbon || !currentAbon)?'cart-plus':'cart-check'" variant="white" class="mx-1"></b-icon>
                  </b-button>

                  

                </template>  

                <!--div class="alert alert-info">! {{currentAbon}} !</div-->

              </b-overlay>
            </div>
        </b-sidebar>





    </div>
</template>

<script>

export default {
  name: 'SpeciaListy',
  props: {
    settings: {
      type: Object,
      required: true
    },
    /*
    auth: {
      type: Object,
      required: true
    },
    */
    abons: {
      type: Object,
      required: false
    },
    visitor: {
      type: Object,
      required: false
    },
    need2update: {
      type: Object,
      required: false
    },
    worker2open: {
      type: Object,
      required: false
    }
    
  },
  data: () => ({
    busy: true,
    book_busy: false,
    services: null,
    currentService: null,
    currentServicePost: null,
    fields: [],
    specialists: null,
    fields_specialists: [],
    specialist: null,
    currentDayIndex: 0,
    //currentWeekIndex: 0,
    //weeks: [],
    shifts: null,
    states: null,
    fields_specialist: [],
    tabIndex:0,
    checkedShifts:[],
    currentShiftsBlock: null,
    selectFr: null,
    selectTo: null,
    selectHall: null,
    hallNames: null,
    ondate: null,
    booking_email: null,
    booking_name: null,
    useAbon: false,
    currentAbon: null,
    readyToPay: true

  }),  
  methods: {
        getServices(){
            this.busy = true;
            this.$axios.post('/', {"action":"getservices"} )
                .then((response) => {
                if(response.data.success != false && response.data.success != 'false'){
                    this.services = response.data.services

                    this.specialists = response.data.specialists
                    this.shifts = response.data.shifts
                    this.hallNames = response.data.halls

                    if(this.specialists.length>0) {
                        //this.setSpecialist(this.specialists[0])
                        if(!this.worker2open)
                            this.setSpecialist(this.specialists[0])
                        else{
                            this.jumpToSpecialist(this.worker2open)
                            this.$emit('clearJump')    
                        }    
                    }    

                    //this.grplessons = response.data.grplessons
                    this.busy = false;

                }else{

                    this.busy = false;
                    if(response.data.error=="auth" || response.data.error=="init"){
                        this.$emit('needlogin')
                    }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                    }
                }  
                })
                .catch(error => {
                    this.busy = false
                    console.error('There was an error!', error)
                });
        },
        
        setSpecialist(item) {
          this.specialist = item
          //this.currentWeekIndex = Object.keys(item.hallobj[0].scheme[0].weeks)[0]
          //this.currentWeekIndex = ((Object.keys(item.hallobj[0].scheme[0].weeks)).sort())[0]
          this.currentDayIndex = 0
          this.setDayPosition()
          this.checkedShifts = []
        /*
          this.$nextTick().then(
            this.currentWeekIndex = Object.keys(specialist.hallobj[0].scheme[0].weeks)[0]
          )
        */          
        },

        jumpToSpecialist(rn) {
          var worker
          this.specialists.forEach((w)=>{
            if(w.worker==rn){
                worker=w
            }
          })
          if(worker) this.setSpecialist(worker)
          else this.setSpecialist(this.specialists[0])
        },

        clickDayNext(){

            if(!this.isNextDay)return
            //if(!(this.specialist && this.specialist.hallobj[0] && this.specialist.hallobj[0].scheme[0]))return
            if(!(this.specialist && this.specialist.hallobj[0] && this.specialist.hallobj[0].dayindex))return
            /*
            let day = document.getElementById('dt-'+darr[this.currentDayIndex])
            let c = document.getElementsByClassName('rasp-container')[0]
            let a = document.getElementsByClassName('rasp-col')[document.getElementsByClassName('rasp-col').length-1]

            if(day && c && a && day.offsetLeft +c.clientWidth - a.offsetLeft - a.clientWidth > 0) return
            */

            //let darr = Object.keys(this.specialist.hallobj[0].scheme[0].daysarr)
            //let darr = Object.keys(this.specialist.hallobj[0].dayindex)
            let darr = this.specialist.dayindexfull
            if(darr.length-1 > this.currentDayIndex) this.currentDayIndex++
            this.setDayPosition(darr)
        },
        clickDayPrev(){
            if(this.currentDayIndex > 0) this.currentDayIndex--
            //if(!(this.specialist && this.specialist.hallobj[0] && this.specialist.hallobj[0].scheme[0]))return
            if(!(this.specialist && this.specialist.hallobj[0] && this.specialist.hallobj[0].dayindex))return
            //let darr = Object.keys(this.specialist.hallobj[0].scheme[0].daysarr)
            //let darr = Object.keys(this.specialist.hallobj[0].dayindex)
            let darr = this.specialist.dayindexfull
            this.setDayPosition(darr)
        },
        setDayPosition(darr){
            let cont = document.getElementById('rasp-content')
            if(!darr && cont) cont.style.left = '0px'
            else if(cont){
                    let day = document.getElementById('dt-'+darr[this.currentDayIndex])
                    if(day && cont) cont.style.left = '-'+day.offsetLeft+'px'
                }
        },
        /*
        onRowClicked_gruppa(item) {
          console.log(item)
          this.tabIndex = 4
          this.gruppa = item
        },
        */
        getSpecialists(){
            this.busy = true;
            this.specialist = null
            this.$axios.post('/', {"action":"getspecialists","rn": this.currentService} )
                .then((response) => {
                if(response.data.success != false && response.data.success != 'false'){
                    this.specialists = response.data.specialists
                    this.shifts = response.data.shifts

                    if(this.specialists.length>0) this.setSpecialist(this.specialists[0])
                    this.currentServicePost = null

                    this.busy = false;

                }else{

                    this.busy = false;
                    if(response.data.error=="auth" || response.data.error=="init"){
                        this.$emit('needlogin')
                    }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                    }
                }  
                })
                .catch(error => {
                    this.busy = false
                    console.error('There was an error!', error)
                });
        },
        /*
        floatToTime(t){
          var tmp = t-Math.trunc(t)
          var out = (Math.trunc(t)).toString() + ':'
          out += (tmp>0)?(Math.round(tmp*100)).toString():'00'
          return out
        },
        */

        floatToTime(t){
            //var tmp = t-Math.trunc(t)
            var tmp = (t % 1)
            var out = (Math.trunc(t)).toString() + ':'
            if(tmp > 0){
                var priznak = Math.round(tmp*100)
                out += (priznak<10)?'0':''
                out += priznak.toString()
            }else{
                out += '00'
            }
            return out
        },

        checkShift(e){
            let id = e.target.id
            if(!id || id.substring(0, 4)!='sft_')return
            let n = this.checkedShifts.indexOf(id)
            let idx, idx_min,idx_max,attrs,attr
            if(n===-1) {
                attr  = id.split('_')
                attrs = attr[2]+'_'+attr[3]+'_'+attr[4]
                if(this.currentShiftsBlock != attrs || this.checkedShifts.length == 0){
                    this.currentShiftsBlock = attrs
                    this.checkedShifts=[]
                }else{
                    idx     = parseInt(attr[1])
                    idx_min = parseInt(this.checkedShifts[0].split('_')[1])
                    idx_max = parseInt(this.checkedShifts[this.checkedShifts.length-1].split('_')[1])

                    console.log(idx, idx_min, idx_max)

                    if(idx > idx_max+1 || idx < idx_min-1) {
                        this.checkedShifts=[]
                    }    
                }
                this.checkedShifts.push(id)
                this.checkedShifts.sort()
                //:id="'sft_' + sft_idx + '_' + specialist.worker + '_' + hall.hallobjRN + '_' + dt + '_' + sft.shiftSpecRN"
                //      0        1                2                         3                    4            5                         
            }else 
                if(this.checkedShifts.length>1) {
                    attr  = id.split('_')
                    attrs = attr[2]+'_'+attr[3]+'_'+attr[4]
                    idx     = parseInt(attr[1])
                    idx_min = parseInt(this.checkedShifts[0].split('_')[1])
                    idx_max = parseInt(this.checkedShifts[this.checkedShifts.length-1].split('_')[1])

                    if(idx == idx_max) this.checkedShifts = this.checkedShifts.slice(0, n)
                    else if(idx == idx_min) this.checkedShifts = this.checkedShifts.slice(n+1)
                    else this.checkedShifts = this.checkedShifts.slice(0, n+1)
                    //this.checkedShifts = this.checkedShifts.slice(0, n).concat(this.checkedShifts.slice(n+1))    
                }else this.checkedShifts=[]
            console.log(this.checkedShifts)


        },
        shiftCheckedPos(sft_idx){
            sft_idx = parseInt(sft_idx)
            if(this.checkedShifts.length>1) {
                let idx_min = parseInt(this.checkedShifts[0].split('_')[1])
                let idx_max = parseInt(this.checkedShifts[this.checkedShifts.length-1].split('_')[1])
                if(idx_min==sft_idx) return 'shift-checked-begin'
                else if(idx_max==sft_idx) return 'shift-checked-end'
                else if(idx_min<sft_idx && idx_max>sft_idx) return 'shift-checked-middle'
                return
            }    
            return
        },
        changeAbon(){
            this.useAbon = (this.currentAbon!=null)
        },
        validDateAbon(abn,dt){
            if(!abn) return true
            if(abn.datefr && new Date(dt) < new Date(abn.datefr)) return false
            if(abn.dateto && new Date(dt) > new Date(abn.dateto)) return false
            if(!this.currentAbon) this.currentAbon = abn.rn //первый валидный
            return true
        },
        validDateServiceAbon(abn,dt,service){
            if(!abn) return true
            if(abn.datefr && new Date(dt) < new Date(abn.datefr)) return false
            if(abn.dateto && new Date(dt) > new Date(abn.dateto)) return false
            if(abn.serviceRN != service) return false
            if(!this.currentAbon) {
                this.currentAbon = abn.rn //первый валидный
                this.useAbon = true
            }    
            return true
        },

        updateStates(){

            let sp = this.specialist
            if(!sp) return true
//            console.log('Worker',sp.worker,sp.hallobj[0].scheme[0].days[0].shiftRN);

            var postData = {
                "action"  :"getspecialiststates",
                "specialist" : sp.worker
            }

            var axiosConfig = {
                headers: {
                'Content-Type': 'application/json',
                },
                'withCredentials': true
            }

            this.busy = 1
            ///this.states = null НЕНАДО СБРАСЫВАТЬ

            this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
                .then((response) => {
                    if(response.data.success != false  && response.data.success != 'false'){

                        if(response.data.states) this.states = response.data.states

                        // ПЕРЕЧИТАТИ СТЕЙТИ!!!!
                        this.busy = false

                    
                    }else{
                        this.busy = false

                        if (!this.errorMaster(response.data.error)){
                            console.log('aga')
                            return
                        }     

                    }  
                })
                .catch(error => {
                    console.error('There was an error!', error)
                    this.busy = false
                    this.$toasted.error('Error').goAway(2000)
                });  

        },


        switchSidebar(el,action){
            switch (action){
                case 'on':
                    if(document.getElementById(el) && document.getElementById(el).style.display=='none')
                        this.$root.$emit('bv::toggle::collapse', el)
                        //console.log('-----on')
                    break
                case 'off':    
                    if(document.getElementById(el) && document.getElementById(el).style.display!='none')
                        this.$root.$emit('bv::toggle::collapse', el)
                        //console.log('-----off')
            }
        },
        
        isLogined(){
            //return (this.auth && this.auth.visitor && this.auth.visitor.visitorRN)
            return (this.visitor && this.visitor.visitorRN)
        },

        //*********************************************** */    

        goBook(){
            if(!this.checkedShifts){
                console.error('List is empty')
                return false
            }
            this.book_busy = true

            let attr1 = this.checkedShifts[0].split('_')
            let worker      = attr1[2]
            let hall        = attr1[3]
            let ondate      = attr1[4]
            let shiftStart  = attr1[5]
            let shiftEnd = shiftStart

            if(this.checkedShifts.length>1){
                let attr2 = this.checkedShifts[this.checkedShifts.length-1].split('_')
                shiftEnd  = attr2[5]
            }

            //:id="'sft_' + sft_idx + '_' + specialist.worker + '_' + hall.hallobjRN + '_' + dt + '_' + sft.shiftSpecRN"
            //      0        1                2                         3                    4            5             
            
         
            var postData = {
                "action"  :"bookspecialist",
                "date"    : ondate,
                "hallobj" : hall,
                "worker"  : worker,
                "shiftStart" : shiftStart,
                "shiftEnd": shiftEnd,
                "service" : this.currentServicePost,
                "abon"    : (this.currentAbon && this.useAbon)?this.currentAbon:null,

                "email"   : (!this.isLogined())?this.booking_email:null,
                "name"    : (!this.isLogined())?this.booking_name:null,
                "xworker"   : this.specialist.xworker,  
                "timefr"    : this.selectFr,
                "timeto"    : this.selectTo,
                "xservice"  : this.services[this.currentServicePost]

            }

            var axiosConfig = {
                headers: {
                'Content-Type': 'application/json',
                },
                'withCredentials': true
            }
            this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success != false  && response.data.success != 'false'){

                        this.updateStates()                         // ПЕРЕЧИТАТИ СТЕЙТИ!!!!

                        //console.log(response.data)
                        //this.init()
                        //console.log('init')

                        if(this.currentAbon && this.useAbon) {
                            //абонемент
                            this.$toasted.success('Бронювання із застосуванням абонементу було успішним!').goAway(2000)
                            this.book_busy = false
                            this.checkedShifts = []
                            this.switchSidebar('rightsidebar','off')
                            ////this.getAbonsVisits()
                        }else /*if(response.data.liqpay.data && response.data.liqpay.signature)*/{

                            this.$emit('onupdatebasket')    
                            this.$toasted.success('Бронювання було успішним! Квитки необхідно оплатити').goAway(2000)
                            this.book_busy = false
                            this.checkedShifts = []
                            this.switchSidebar('rightsidebar','off')
                            
                            if(this.readyToPay && !this.useAbon) this.$emit('readytopay')    
                            

                            /*

                                if(response.data.errormessage){  
                                    this.$toasted.error('Увагf! Не вдалося забронювати відвідування на наступні дні : '+response.data.errormessage).goAway(6000)
                                }

                                this.unpayedticketsTmp = this.unpayedtickets

                                response.data.checks.forEach((c) =>{  
                                    c.shift   = this.currentShift //обратить внимсан7ие!!!
                                    this.unpayedticketsTmp.push(c)
                                })

                                this.unpayedtickets = this.unpayedticketsTmp
                                this.book_busy = false    
                                this.checkedSeats = []
                                                
                                if(this.readyToPay && this.unpayedtickets.length>0){
                                    this.$bvModal.show('unpayedtickets-modal')
                                }  
                            */
                           
                        }
                    
                    }else{
                        this.book_busy = false

                        if (!this.errorMaster(response.data.error)){
                            console.log('aga')
                            return
                        }     

                        if(response.data.code) console.log('Помилка № '+response.data.code)

                        if(response.data.code === 23){
                            this.$toasted.error('Нажаль по цьому абонементу вже використано візити:(').goAway(6000)
                            /*
                            this.book_busy = true
                            this.updateStates(false)
                            this.book_busy = false  
                            */
                            
                        }else if(response.data.code === 8){
                            this.$toasted.error('Не введено електрону адресу!:(').goAway(6000)

                        }else{  
                            this.$toasted.error('Нажаль забронювати це вже неможливо:(').goAway(6000)

                            if(response.data.message)
                                this.$toasted.error(response.data.message).goAway(4000)
                            else if(response.data.error)
                                this.$toasted.error(response.data.error).goAway(4000)
                            else  
                                this.$toasted.error('Error').goAway(2000)  


                            this.book_busy = true

                            this.updateStates()  // !!!!!!! ПЕРЕЧИТАТЬ СТЕЙТЫ!!!!

                            this.book_busy = false 
                        }  
                    }  
                })
                .catch(error => {
                    console.error('There was an error!', error)
                    this.book_busy = false
                    this.$toasted.error('Error').goAway(2000)
                });  
            },

            getState(worker,date,shift){
                let states = this.states
                if(states && states[worker] && states[worker][date] && states[worker][date])
                    return states[worker][date][shift]
                else
                    return -1    
            },
        
            resetAbonByService(){
                if(this.$parent.$data.auth && this.$parent.$data.auth.abons && Object.keys(this.$parent.$data.auth.abons).length>1){
                    this.currentAbon = null
                    this.useAbon = false
                    let ab = this.$parent.$data.auth.abons
                    let ond = this.ondate
                    let serv = this.currentServicePost
                    Object.keys(ab).forEach((a)=>{
                        if(!this.currentAbon && this.validDateServiceAbon(ab[a],ond,serv)) {
                            this.currentAbon = a
                            this.useAbon = true
                        }    
                    })        
                }    
            },    

    //*********************** */

    startTouch(e){
      var et = e.touches
      if(!et)return
      if(et.length>0){
        this.startX = et[0].clientX
        this.onDrag = true
      }  
    },

    moveTouch(e){
      var et = e.touches
      var dX=0
      if(!et)return
      if(this.onDrag){
        if(et.length>0){
          //e.preventDefault()
          dX=this.startX - et[0].clientX

          if(dX>30) {
            this.clickDayNext()
            this.startX = et[0].clientX
          }else if(dX < -30){ 
            this.clickDayPrev()
            this.startX = et[0].clientX
          }
          //e.stopPropagation()
        }
      }        
    },
    endTouch(){
      if(this.onDrag){
        this.startX = null
        this.onDrag = false
      }  
    },
    //*********************** */    

      

        //************************************************

        errorMaster(er){
            if(!er) return true
            if(er=="init"){
                if(this.auth){
                    this.$emit('needlogin')
                    console.log('Re LOGIN')
                    return false
                }else{
                    this.$emit('needlogin')
                    //this.init()
                    console.log('Re INIT')
                    return false
                }
            }else if(er=="auth"){
                this.$emit('needlogin')
                console.log('auth - needlogin')
                return false
            }else{
                console.log('Re INKNOWN',er)
                return true
            }
        },


    
  },
  
  mounted() {
    this.getServices()
  },

  created() {

  },


    watch:{
        checkedShifts: function() {

            this.currentAbon = null
            this.useAbon = false
            this.readyToPay = true

            if(!this.checkedShifts || this.checkedShifts.length==0){
                this.selectFr = null
                this.selectTo = null
                this.selectHall = null
                this.selectHallName = null
                this.ondate = null
                this.currentServicesList = null
                //this.currentServicePost = null
                return
            }
            let tmFr,tmTo
            let attr = this.checkedShifts[0].split('_')
            //let sftFr = this.checkedShifts[0].split('_')[5]
            let sftFr = attr[5]

            let sftTo = this.checkedShifts[this.checkedShifts.length-1].split('_')[5]
            Object.values(this.shifts).forEach((s)=>{
                if(!tmFr)
                    s.spec.forEach((sf)=>{
                        if(sf.shiftSpecRN===sftFr){
                            tmFr = this.floatToTime(sf.timeFr)
                        }
                        if(sf.shiftSpecRN===sftTo){
                            tmTo = this.floatToTime(sf.timeTo)
                        }
                    })
            })
            this.selectFr = tmFr
            this.selectTo = tmTo
            this.selectHall = attr[3]
            this.ondate = attr[4]

            if(!this.currentService){
                var sFlag
                this.specialist.hallobj.forEach((h)=>{
                    if(h.hallobjRN == this.selectHall){
                        this.currentServicesList = h.scheme[0].services
                        if(this.currentServicesList.length===1){
                            this.currentServicePost = this.currentServicesList[0]['serviceRN']
                        }else
                            if(this.currentServicePost){
                                sFlag=false
                                this.currentServicesList.forEach((s)=>{
                                    if(s['serviceRN']===this.currentServicePost){
                                      sFlag=true // оставляем ранее установленное значение услуги
                                    }
                                })
                                if(!sFlag) this.currentServicePost = this.currentServicesList[0]['serviceRN']
                                //this.currentServicePost = null
                            }else{
                                this.currentServicePost = this.currentServicesList[0]['serviceRN']
                            }    
                    }    
                })
            }else{
                this.currentServicePost = this.currentService
            }    
            
        },

        specialist: function(sp){
            if(!sp) return true
            this.updateStates()
        },

        need2update: function(nw, o){
                if(nw && !o){
                    this.$emit("onUpdateStates")
                    this.updateStates()
                }
            },
       
    },

    computed: {
        isNextDay: function(){
            if(!this.specialist){
                console.log('False 1')
                return false
            }    
            //let darr = Object.keys(this.specialist.hallobj[0].scheme[0].daysarr)
            let darr = Object.keys(this.specialist.hallobj[0].dayindex)

            let day = document.getElementById('dt-'+darr[this.currentDayIndex])
            let c = document.getElementsByClassName('rasp-container')[0]
            let a = document.getElementById('dt-'+darr[darr.length-1])

            if(day && c && a && day.offsetLeft +c.clientWidth - a.offsetLeft - a.clientWidth > 0) return false
            return true
        },

        isVisibleGoPay: function(){
            return !this.useAbon || !this.currentAbon
        }    
      
       
        
    }
}
</script>

<style scoped>

.list-group-item.active {
    color: #fff;
    background-color: #034f9b;
    border-color: #003366;
}    
.raspisanie{
  display: flex;
  overflow: auto;
}

.raspisanie-col {
  border: 1px solid #e0e0e0;
  flex-grow: 1;  
}

.raspisanie-day{
  background-color: #003366!important;
  color: #fff!important;
  text-align: center;
}
/*
.weeks{
    display: flex;
    justify-content: flex-start;
}
*/
.sft--0 {
    color: #adb5bd;
    background-color: #fff;
    border-color: #343a40;
    cursor: no-drop;
}

.sft--2 {
    border: 4px solid;
    padding: 4px !important;
    border-color: #45a011;
    position: relative;
}

.booked{
    position: absolute;
    top: -11px;
    left: -11px;
}


.sft-0{
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.sft-1{
    color: #191557;
    background-color: #d4e4ed;
    border-color: #c8c3e6;
}

.sft-2{
    color: #465715;
    background-color: #ede8d4;
    border-color: #e6dec3;
}

/**/
.hdr-specialist{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

@media (max-width: 575px) {
    .hdr-specialist{
        align-items: stretch;
        flex-direction: column;
    }
}

.rasp-root{
    display: flex;
}
.rasp-container{
    flex-grow: 2;
    overflow: hidden;
}

.rasp-content{
    display: flex;
    width: 100%;
    position: relative;
    left: 0px;
    transition: all 0.2s ease-in-out;
}
.rasp-col{
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='1' height='60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23C4C4C4' stroke-linecap='round' stroke-linejoin='round' stroke-dasharray='4 8' d='M.5 59.5V.5'/%3E%3C/svg%3E");
    background-origin: border-box;
    background-position-x: right;
    background-position-y: top;
    background-repeat: repeat-y;
    padding: 0 1px;
}
.rasp-day{
    min-width: 90px;
}
.rasp-shift{
    border-radius: 10px;
}

.shift-checked{
    border: 4px solid;
    padding: 4px !important;
}

.shift-checked-begin{
    border-bottom: 1px dashed;
    padding-bottom: 7px !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.shift-checked-end{
    border-top: 1px dashed;
    padding-top: 7px !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.shift-checked-middle{
    border-bottom: 1px dashed;
    padding-bottom: 7px !important;
    border-top: 1px dashed;
    padding-top: 7px !important;    
    border-radius: 0;
}



/*
@media (max-width: 575px) {

}


@media (hover: none) {

}    

*/

</style>
