<template>
    <div class="salepoints">
        <!--
        <h4>Hall Rp</h4>
        <pre>
            {{hall_rp}}
        </pre>
        <h4>Places</h4>
        <pre>
            {{places}}
        </pre>
        <h4>Salepoints</h4>
        <pre>
            {{salepoints}}
        </pre>
        -->
        <template v-for="p in places">
            <b-card no-body :key="p.rn" class="mb-3">
                <template #header>
                    <h5 class="mb-0">{{p.name}}</h5>
                </template>

                <!--b-card-body>
                <b-card-title>Card Title</b-card-title>
                <b-card-sub-title class="mb-2">Card Sub Title</b-card-sub-title>
                <b-card-text>
                    Some quick example text to build on the card title and make up the bulk of the card's
                    content.
                </b-card-text>
                </b-card-body-->

                <b-list-group flush>
                    <b-list-group-item 
                        :role="(s.point_rn!=point_rn)?'button':''" 
                        @click="setSalepoints(s.point_rn)" 
                        :key="s.point_rn" 
                        v-for="s in salepoints.filter(itm => itm['place_rn']==p.rn)"
                        :class="(s.point_rn==point_rn)?'active-salepoint':'salepoint'"
                    ><b-icon :icon="(s.point_rn==point_rn)?'geo-alt-fill':'geo-alt'" class="mr-1" scale="0.8"/>{{s.point_name}}
                    <!--pre>
                        point_rn = {{point_rn}}
                        {{s}}
                    </pre-->    
                    
                    </b-list-group-item>    
                </b-list-group>
            </b-card>
        </template>    
    </div>
</template>

<script>

export default {
  name: 'SalePoints',
  props: {
    places:{
        type: Object,
        required: false
    },
    salepoints:{
        type: Object,
        required: false      
    },
    point_rn:{
        type: Object,
        required: false      
    }
    
  },
  data: () => ({
   
    
  }),  
  methods: {
    setSalepoints(value) {
        this.$root.$emit("change-salepoint", value)
    }
  
    
  },
  computed:{

  },  
     
  mounted() {
   
  },

  created() {
   
  },

}
</script>

<style scoped>

.active-salepoint{
    background-color:#fff4ba;
}
.list-group-item{
    font-size: 1.1em;
}
.salepoint:hover{
    background-color: rgb(209, 209, 209);
}
/*
@media (max-width: 575px) {

}


@media (hover: none) {

}    

*/

</style>
